import { getData } from './request.js'

// 获取规则列表（需传projectId参数）
export function getMonitorRoleList(params) {
    return getData({
        url: '/mapi/monitor-role/list',
        method: 'get',
        params: params // 接收projectId等参数
    })
}

// 修改 monitor-role.api.js 中的 list 方法
export function getProjectMonitorRoleList(projectId) { // 接收projectId参数
    return getData({
        url: `/mapi/monitor-role/${projectId}`, // 使用RESTful路径参数
        method: 'get' // 保持GET方法不变
    })
}

// 新增规则
export function addMonitorRole(data) {
    return getData({
        url: '/mapi/monitor-role/add',
        method: 'post',
        data: data
    })
}

// 修改规则
export function updateMonitorRole(data) {
    return getData({
        url: '/mapi/monitor-role/update',
        method: 'put',
        data: data
    })
}

// 删除规则
export function deleteMonitorRole(data) {
    return getData({
        url: '/mapi/monitor-role/delete',
        method: 'delete',
        data: data
    })
}

