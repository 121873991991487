<template>
  <div class="container">
    <el-form :loading="loading" ref="form" :model="preFrom" label-width="80px"></el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">
          新建规则
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-refresh-right" size="mini" @click="getDataList">
          刷新
        </el-button>
      </el-col>
    </el-row>
    <el-table border stripe :loading="loading" :data="DataList" style="margin-top: 5px;">
      <el-table-column label="规则名称" align="center" prop="roleName"/>
      <el-table-column label="监控类型" align="center" prop="monitorType">
        <template slot-scope="scope">
          {{ getMonitorType(scope.row.monitorType) }}
        </template>
      </el-table-column>
      <el-table-column label="投放人群" align="center" prop="targetType"/>
      <el-table-column label="优化目标" align="center" prop="unitType"/>
      <el-table-column label="最高价" align="center" prop="maxPrice"/>
      <el-table-column label="触发价" align="center" prop="tipPrice"/>
      <el-table-column label="默认价" align="center" prop="defaultPrice"/>
      <el-table-column label="深度优化目标" align="center" prop="deepUnitType"/>
      <el-table-column label="深度最高价" align="center" prop="deepMaxPrice"/>
      <el-table-column label="深度提示价" align="center" prop="deepTipPrice"/>
      <el-table-column label="深度默认价" align="center" prop="deepDefaultPrice"/>
      <!-- 明确区分作品和助推的天数字段 -->
      <el-table-column label="星视频作品可投流天数" align="center" prop="videoWorksDay"/>
      <el-table-column label="星视频助推可投流天数" align="center" prop="videoSupplementDay"/>
      <el-table-column label="星任务作品可投流天数" align="center" prop="taskWorksDay"/>
      <el-table-column label="星任务助推可投流天数" align="center" prop="taskSupplementDay"/>

      <el-table-column label="操作" align="center" width="180px">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">
            修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗 -->
    <el-dialog :title="dialog.title" :visible.sync="dialog.open" width="50%" append-to-body>
      <el-form v-loading="loading" :model="preFrom" ref="ruleForm" label-width="180px">
        <el-form-item label="项目组ID" prop="roleName">
          <el-input disabled v-model="preFrom.projectId"></el-input>
        </el-form-item>
        <el-form-item label="规则名称" prop="roleName">
          <el-input v-model="preFrom.roleName" placeholder="请输入规则名称"></el-input>
        </el-form-item>
        <el-form-item label="监控类型" prop="monitorType">
          <el-select v-model="preFrom.monitorType" placeholder="请选择">
            <el-option
                v-for="item in monitorTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="preFrom.monitorType === 1">
          <el-form-item label="投放人群" prop="targetType">
            <el-select v-model="preFrom.targetType" placeholder="请选择">
              <el-option
                  v-for="item in targetTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优化目标" prop="unitType">
            <el-select v-model="preFrom.unitType" placeholder="请选择">
              <el-option
                  v-for="item in unitTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优化目标价格上限" prop="maxPrice">
            <el-input-number v-model="preFrom.maxPrice" :min="0" :precision="2"></el-input-number>
          </el-form-item>
          <el-form-item label="优化目标盈亏线" prop="tipPrice">
            <el-input-number v-model="preFrom.tipPrice" :min="0" :precision="2"></el-input-number>
          </el-form-item>
          <el-form-item label="优化目标正常价" prop="defaultPrice">
            <el-input-number v-model="preFrom.defaultPrice" :min="0" :precision="2"></el-input-number>
          </el-form-item>
          <el-form-item v-if="preFrom.monitorType === 1" label="深度优化目标" prop="unitType">
            <el-select v-model="preFrom.deepUnitType" placeholder="请选择">
              <el-option
                  v-for="item in deepUnitTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="深度优化目标价格上限" prop="maxPrice">
            <el-input-number v-model="preFrom.deepMaxPrice" :min="0" :precision="2"></el-input-number>
          </el-form-item>
          <el-form-item label="深度优化目标盈亏线" prop="tipPrice">
            <el-input-number v-model="preFrom.deepTipPrice" :min="0" :precision="2"></el-input-number>
          </el-form-item>
          <el-form-item label="深度优化目标正常价" prop="defaultPrice">
            <el-input-number v-model="preFrom.deepDefaultPrice" :min="0" :precision="2"></el-input-number>
          </el-form-item>
        </div >
        <div v-if="preFrom.monitorType === 2">
          <el-form-item label="星视频作品可投流天数" prop="videoSupplementDay">
            <el-input-number v-model="preFrom.videoWorksDay" :min="0" :precision="0"></el-input-number>
          </el-form-item>
          <el-form-item label="星视频助推可投流天数" prop="videoSupplementDay">
            <el-input-number v-model="preFrom.videoSupplementDay" :min="0" :precision="0"></el-input-number>
          </el-form-item>
          <el-form-item label="星任务助推可投流天数" prop="taskSupplementDay">
            <el-input-number v-model="preFrom.taskSupplementDay" :min="0" :precision="0"></el-input-number>
          </el-form-item>
          <el-form-item label="星任务作品可投流天数" prop="taskSupplementDay">
            <el-input-number v-model="preFrom.taskWorksDay" :min="0" :precision="0"></el-input-number>
          </el-form-item>
        </div>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {checkTokenBeforeRouteEnter} from '@/utils/authoVerify'
import {
  getMonitorRoleList,
  addMonitorRole,
  updateMonitorRole,
  deleteMonitorRole,
  getProjectMonitorRoleList
} from "@/api/monitor-role";
import { cloneDeep } from 'lodash';

export default {
  beforeRouteEnter(to, from, next) {
    checkTokenBeforeRouteEnter(to, from, next);
  },
  data() {
    return {
      loading: false,
      DataList: [],
      dialog: {
        open: false,
        title: '',
        type: ''
      },
      // 定义时预存参数
      currentProjectId: this.$route.query.projectId,
      preFrom: {
        id: undefined,
        projectId: this.$route.query.projectId, // 项目ID从路由参数获取
        roleName: undefined,
        monitorType: undefined,
        maxPrice: undefined,
        tipPrice: undefined,
        defaultPrice: undefined,
        deepMaxPrice: undefined,
        deepTipPrice: undefined,
        deepDefaultPrice: undefined,
        videoSupplementDay: undefined,
        videoWorksDay: undefined,
        taskSupplementDay: undefined,
        taskWorksDay: undefined,
        targetType: undefined,
        unitType: undefined,
        deepUnitType: undefined,
      },
      // 下拉选项样例（根据实际需求调整）
      monitorTypeOptions: [
        { value: 1, label: '类型1' },
        { value: 2, label: '类型2' }
      ],
      targetTypeOptions: [
        { value: 'intelligent', label: '系统智能投放' },
        { value: 'custom', label: '自定义用户特征' }
      ],
      unitTypeOptions: [
        { value: 100, label: '互动' },
        { value: 101, label: '有效播放' },
        { value: 102, label: '高质量涨粉' },
        { value: 103, label: '组件点击' },
        { value: 104, label: '激活(提升应用安装)' },
        { value: 105, label: '行为数(提升应用安装)' },
        { value: 106, label: '表单提交(提升线索收集)' },
        { value: 107, label: '行为数(提升线索收集)' },
        { value: 121, label: '付费（提升应用安装）' },
        { value: 122, label: '付费（销售线索收集）' },
        { value: 123, label: '唤端（提升应用活跃）' },
        { value: 124, label: '付费（提升应用活跃）' },
        { value: 125, label: 'ROI(提升应用安装)' },
        { value: 126, label: 'ROI(提升线索收集)' },
        { value: 30, label: '关键行为（提升应用安装）' },
        { value: 31, label: '关键行为（提升线索收集）' },
        { value: 32, label: '有效获客' },
        { value: 33, label: '7日付费次数（提升应用安装）' },
        { value: 34, label: '7日付费次数（提升线索收集）' },
        { value: 35, label: '7日ROI（提升应用安装）' },
        { value: 36, label: '7日ROI（提升线索收集）' },
        { value: 37, label: '添加企业微信' },
        { value: 38, label: '激活' }
      ],
      deepUnitTypeOptions: [
        { value: '104-7', label: '激活-自然日次日留存' },
        { value: '4-251', label: '表单-有效获客' }
      ]
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 监控类型转换
    getMonitorType(type) {
      const map = { 1: '类型1', 2: '类型2' };
      return map[type] || '未知';
    },
    // 删除
    handleDelete(id) {
      this.$confirm("删除后将不可恢复, 是否继续?", "删除规则", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMonitorRole({ id: id }).then(response => {
          this.$message.success(response.msg);
          this.getDataList();
        });
      });
    },
    // 修改
    handleUpdate(item) {
      this.dialog.title = '修改规则';
      this.dialog.type = 'update';
      this.dialog.open = true;
      this.preFrom = cloneDeep(item);
    },
    // 新增
    handleAdd() {
      this.dialog.title = '新建规则';
      this.dialog.type = 'add';
      this.dialog.open = true;
      this.preFrom.projectId = this.$route.query.projectId; // 绑定项目ID
    },
    // 获取列表
    getDataList() {
      this.loading = true;
      // 传projectId到接口（假设接口支持）
      getProjectMonitorRoleList(this.$route.query.projectId).then(response => {
        this.DataList = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    // 提交表单
    onSubmit() {
      if (!this.preFrom.roleName) {
        this.$message.error("角色名称必填");
        return;
      }
      const apiCall = this.dialog.type === 'add'
          ? addMonitorRole(this.preFrom)
          : updateMonitorRole(this.preFrom);
      apiCall.then(response => {
        this.$message.success(response.msg);
        this.getDataList();
        this.dialog.open = false;
        this.resetForm();
      }).catch(() => {
        this.$message.error("请求失败");
      });
    },
    // 重置表单
    resetForm() {
      this.preFrom = {
        id: undefined,
        projectId: this.$route.query.projectId, // 保留项目ID
        roleName: undefined,
        monitorType: undefined,
        maxPrice: undefined,
        tipPrice: undefined,
        defaultPrice: undefined,
        videoSupplementDay: undefined,
        taskSupplementDay: undefined,
        targetType: undefined,
        unitType: undefined
      };
    }
  }
}
</script>

<style>
.container {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
